import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { getItemsCountFromDB, selectCartItemsCount, setCartEnabled, setCartAllowed, cartEnabled, } from 'srs.sharedcomponents/lib/esm/redux/slices/cartSlice'
import { selectWishListMode } from 'srs.sharedcomponents/lib/esm/redux/slices/wishlistSlice'
import { LinkType, wishListDbInteractions } from 'srs.sharedcomponents/lib/esm/utils/enums'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronLeft, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import TopNavigationComponent from 'srs.sharedcomponents/lib/esm/components/header/srs.topNavigation.component'
import { useTranslation } from 'react-i18next'
import { i18nNamespaces as namespace } from 'srs.sharedcomponents/lib/esm/i18n/i18n'
import { dealershipName, fullName, customerDunningBlockInfo, bearerToken, isValidCustomer } from 'srs.sharedcomponents/lib/esm/redux/slices/userSlice'
import { setLastVisitedCatID, setLastVisitedSearch } from 'srs.sharedcomponents/lib/esm/redux/slices/productlistSlice'
import { removeSpecialCharacter } from 'srs.sharedcomponents/lib/esm/utils/validators'
import { mainMenuItems, isCurrentCountryIndependent, hasDefaultPaymentMethods, } from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import DOMPurify from 'dompurify'
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers'
import { IMainMenuItem } from 'srs.sharedcomponents/lib/esm/models/srs.mainMenuItem.model'

interface IHeaderComponentProps {
  toggleLeftSideMobileMenu?: () => void
}

const HeaderComponent = (props: IHeaderComponentProps) => {
  const welcome = useAppSelector((state) => state?.welcome?.welcome)
  const { t } = useTranslation()
  const history = useHistory()
  const [searchString, setSearchString] = useState('')
  const [productSearchError, setProductSearchError] = useState(false)
  const dunningblock = useAppSelector(customerDunningBlockInfo)
  const isCartEnabled = useAppSelector(cartEnabled)
  const currentCountryIndependent = useAppSelector(isCurrentCountryIndependent)
  const defaultPaymentMethods = useAppSelector(hasDefaultPaymentMethods)

  const userLoggedIn = !!useAppSelector(bearerToken)
  const anonymousUserCannotAddToCart = stringToBoolean(
    process.env.REACT_APP_ANONYMOUS_USER_CANNOT_ADD_TO_CART,
  )
  const customerStatusPopupEnabled = stringToBoolean(
    process.env.REACT_APP_CUSTOMER_STATUS_POPUP_ENABLED,
  )
  const validCustomer = useAppSelector(isValidCustomer)
  useEffect(() => {
    setProductSearchError(false)
  }, [history.length])
  const onChange = (event: any) => {
    setSearchString(removeSpecialCharacter(event.target.value))
  }
  const onSubmit = (event: any) => {
    if (event.key === 'Enter' || event.type === 'click') {
      event.preventDefault()
      if (errMesage()) {
        setProductSearchError(true)
        return
      }
      if (productSearchError) setProductSearchError(false)
      dispatch(setLastVisitedCatID(null))
      dispatch(setLastVisitedSearch(searchString))
      history.push(`ProductListCatalog?search=${searchString}`)
      setSearchString('')
    }
  }
  function errMesage() {
    if (searchString.length === 0) {
      return t(`valSearch`, {
        ns: namespace.coreSrsPrimary,
      })
    }
    if (searchString.length < 3 || searchString.length > 100) {
      return t(`Search_MinSearchError`, {
        ns: namespace.coreSrsPrimary,
      })
    }
  }
  const dispatch = useAppDispatch()
  const [cartCount, setCartCount] = useState<string>('0')
  const cartItemsCount: number = useAppSelector(selectCartItemsCount)
  const wishListMode: wishListDbInteractions = useAppSelector(selectWishListMode)
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  const [isLeftMenuVisible, setIsLeftMenuVisible] = useState(false)
  const [componentName, setComponentName] = useState('')
  const fullNameDb = useAppSelector(fullName)
  const dealershipNameDb = useAppSelector(dealershipName)
  const menuItems = useAppSelector(mainMenuItems)

  /**
   * Toggle mobile menu
   */
  const handleMobileMenu = () => {
    setIsMenuExpanded(!isMenuExpanded)
  }

  useEffect(() => {
    //after moveFromWishListToCart
    if (wishListMode === wishListDbInteractions.moveToCartFulfilled) dispatch(getItemsCountFromDB())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishListMode])

  useEffect(() => {
    getComponentNameToVisualizeSideMenu()
    // eslint-disable-line react-hooks/exhaustive-deps
  })

  const getComponentNameToVisualizeSideMenu = () => {
    setComponentName(history.location.pathname)
    if (componentName === '' || componentName === '/' || componentName === '/ProductListCatalog') {
      setIsLeftMenuVisible(true)
    } else {
      setIsLeftMenuVisible(false)
    }
  }
  useEffect(() => {
    if (cartItemsCount !== null) {
      cartItemsCount > 99 ? setCartCount('99+') : setCartCount(cartItemsCount.toString())
    }
  }, [cartItemsCount])

  useEffect(() => {
    const isValidCustomer = customerStatusPopupEnabled === true ? validCustomer === true : true
    const isValidCountry = currentCountryIndependent === true
    const isAnonymousCartEnabled =
      !anonymousUserCannotAddToCart && isValidCountry && defaultPaymentMethods === true

    const enableCart = () => {
      const showCart = userLoggedIn ? isValidCustomer : isAnonymousCartEnabled
      return showCart
    }

    const allowCart = () => {
      const dunningBlocked = dunningblock?.isDunningBlocked ?? true
      const allowCart = userLoggedIn ? isValidCustomer && !dunningBlocked : isAnonymousCartEnabled
      return allowCart
    }

    const isCartEnabled = enableCart()
    const isCartAllowed = allowCart()

    dispatch(setCartEnabled(isCartEnabled))
    dispatch(setCartAllowed(isCartAllowed))
  }, [
    userLoggedIn,
    dunningblock,
    validCustomer,
    currentCountryIndependent,
    defaultPaymentMethods,
  ])

  const checkIfMenuItemNeedsToExpandLeftMenu = (item: IMainMenuItem) => {
    const leftMenuIsEnabled = stringToBoolean(process.env.REACT_APP_LEFT_NAVIGATION_ENABLED)
    const isMobile = window.innerWidth < 992
    if(props.toggleLeftSideMobileMenu && !leftMenuIsEnabled && isMobile){
      return <Link key={item.url} to={"#"} className='header__button' id={item.name} onClick={() => {props?.toggleLeftSideMobileMenu?.(); handleMobileMenu();}}> {item.name} </Link>
    } 
    return (<Link onClick={handleMobileMenu} key={item.url} className='header__button' id={item.name} to={item.url} target={item.placementType}>
                            {item.name}
                          </Link>)
  }

  const currentPath = window.location.pathname

  return (
    <Container>
      <Col className='header'>
        <div className='header__row'>
          <Col lg={12} md={12}>
            <Row>
              <Col className=''>
                <div className={'header__top-nav-desktop'}>
                  <TopNavigationComponent />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12}>
                <Link to='/' className='position-relative'>
                  <span className='header-text' dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t(`HeaderTitle`, {
                      ns: namespace.coreSrsPrimary,
                    }))
                  }}></span>


                  <img id='headerLogoImg' className='img-fluid logo' src={process.env.REACT_APP_LOGOURL} alt='subaru logo' />
                </Link>
              </Col>

            </Row>
          </Col>
          <Col lg={12} md={12} xs={12} className='ml-auto text-md-right'>
            <Row>
              <Col md={6} className='header__text-right'>
              </Col>
              <Col md={6} className='header__text-right'>
                <form className='form-group m-0 text-right'>
                  <div className='input-group mb-2 mr-sm-2 hideFocus'>
                    <input
                      type='text'
                      className='form-control form-control-lg border-rounded-10'
                      id='search'
                      name='search'
                      value={searchString}
                      onChange={onChange}
                      onKeyDown={onSubmit}
                      placeholder='Search Products'
                    />
                    <div className='invalid-feedback'>Please search here</div>
                    <div className='header__input-group-append'>
                      <button
                        className='input-group-text btn bg-white border-rounded-right-10 border-left-0'
                        id='btnsubmit'
                        type='button'
                        onClick={onSubmit}
                      >
                        <FontAwesomeIcon icon={faSearch} />
                      </button>
                    </div>
                  </div>
                  {productSearchError && (
                    <label id='ProductsearchError' className={`'d-inline-block text-danger`}>
                      {errMesage()}
                    </label>
                  )}
                </form>
              </Col>
              <Col xl={12} lg={12} md={12} className='text-end text-md-start'>
                <span className='header__welcome-text d-block' id='welcomeText'>
                  {t(`txtWelcomeMessage`, {
                    ns: namespace.coreSrsPrimary,
                  })}{' '}
                  {fullNameDb}
                  {dealershipNameDb && ', ' + dealershipNameDb}
                  <span className='ml-1' id='loginname'></span>
                </span>
              </Col>
            </Row>
          </Col>
        </div>
        <Col className='header__row'>
          <Col className='header__links main-navigation'>
            <nav className='navbar navbar-expand-lg w-100 main-menu p-0' id='main-menu'>
              {!isLeftMenuVisible && (
                <button className='menu-button btn btn-dark d-lg-none ml-2' id='sidebarCollapse' onClick={props.toggleLeftSideMobileMenu}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              )}

              <button id='mobileMenuButton' onClick={handleMobileMenu} className='menu-button btn btn-dark d-lg-none ml-auto mr-2'>
                {!isMenuExpanded ? (
                  <FontAwesomeIcon icon={faBars} className='main-menu-icon' />
                ) : (
                  <FontAwesomeIcon icon={faTimes} className='main-menu-icon' />
                )}
              </button>
              <Col className={`${isMenuExpanded ? 'expand navbar-collapse' : 'collapse navbar-collapse'} justify-content-between `}>
                <ul className='nav navbar-nav'>
                  {menuItems?.map((item, i) => {
                    if (item.linkType === LinkType.ReactMarkExt) {
                      if (item.name === 'Home')
                        return (
                          <Link key={item.name.concat(i.toString())} onClick={handleMobileMenu} className='header__button' id={item.name} to='/'>
                            {item.name}
                          </Link>
                        )
                      else {
                        return (
                          <Link
                            key={item.name.concat(i.toString())}
                            className='header__button'
                            id={item.name}
                            to={item.name.replace(/\s/g, '').toLowerCase()}
                            target={item.placementType}
                            onClick={handleMobileMenu}
                          >
                            {item.name}
                          </Link>
                        )
                      }
                    } else if (item.linkType === LinkType.ReactInternal) {
                      if(item.url.toLowerCase() === "/ProductListCatalog".toLowerCase() && currentPath.toLowerCase() === "/ProductListCatalog".toLowerCase()) {
                        return checkIfMenuItemNeedsToExpandLeftMenu(item)
                      }
                      else{
                        return (
                          <Link key={item.name.concat(i.toString())} className='header__button' id={item.name} to={item.url} target={item.placementType} onClick={handleMobileMenu}>
                            {item.name}
                          </Link>
                        )
                      }
                      
                    } else {
                      return (
                        <a id={item.name} key={item.name.concat(i.toString())} className='header__button' href={item.url} target={item.placementType} onClick={handleMobileMenu}>
                          {item.name}
                        </a>
                      )
                    }
                  })}
                </ul>
                <Col md={2} className='header__right-menu row justify-content align-items-center gap-3 w-auto'>
                  <Col lg={4} md={6} className='p-0 w-auto'>
                    {isCartEnabled && (
                      <Link className='position-relative desktop-cart' to='/cart' id='headerCartIcon'>
                        <img src='/images/CartWhite.png' alt='' className='cart-icon' />
                        <span id='lblCartItemsCount' className='BlinkCartItemCount BlinkCartItemCountHeader circle' style={{ color: '#fff', top: -11 }}>
                          <span id='CartItemCount'> {` ${cartCount} `} </span>
                        </span>
                      </Link>
                    )}
                    <div>
                      {isCartEnabled && (
                        <Link className='header__button mobile position-relative' to='/cart' id='headerMobileCartIcon' onClick={handleMobileMenu}>
                          <img src='/images/CartBlack.png' alt='' className='cart-icon' />
                          <span id='lblCartItemsCount' className='BlinkCartItemCount BlinkCartItemCountHeader circle'>
                            <span id='CartItemCount'> {` ${cartCount} `} </span>
                          </span>
                        </Link>
                      )}
                    </div>
                    <div className={'header__top-nav-mobile'}>
                      <TopNavigationComponent handleMobileMenu={handleMobileMenu} />
                    </div>
                  </Col>
                  <Col md={6} className='p-0 w-auto'>
                    <a className='te' href={welcome?.helpLink} target='_blank' id='headerHelpIcon' rel='noreferrer'>
                      <span className='srs-icon-help-circle text-white h3'></span>
                    </a>
                  </Col>
                </Col>
              </Col>
            </nav>
          </Col>
        </Col>
      </Col>
    </Container>
  )
}
export default HeaderComponent
