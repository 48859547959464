import LeftMenuComponent from 'srs.sharedcomponents/lib/esm/shared-components/srs.leftMenu.component'
import { useAppDispatch, useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { Container, Row, Col } from 'react-bootstrap'
import { discard, marketingContent, promote } from 'srs.sharedcomponents/lib/esm/redux/slices/webPartEditorSlice'
import { IZone } from 'srs.sharedcomponents/lib/esm/models/webPartEditor/srs.zone.model'
import CmsButtonsComponent from 'srs.sharedcomponents/lib/esm/components/webPartEditor/srs.cmsButtons.component'
import { getWelcome } from 'srs.sharedcomponents/lib/esm/redux/slices/welcomeSlice'
import { CMSEditorTypes, WelcomeZonesNames } from 'srs.sharedcomponents/lib/esm/utils/enums'
import {
  fillRotatingImages,
  fillSingleImage,
  getImgValues,
  getRotatingImagesValues,
  stringToBoolean,
} from 'srs.sharedcomponents/lib/esm/utils/helpers'
import { HTMLElement, parse } from 'node-html-parser'
import { rotatingImagesTemplate, singleImageTemplate } from 'srs.sharedcomponents/lib/esm/utils/constants'
import { IImagePoco } from 'srs.sharedcomponents/lib/esm/models/webPartEditor/IImagePoco'
import htmlParser from 'html-react-parser'

interface IWelcomeComponentProps {
  isLeftSideMobileMenuExpand?: boolean
  toggleLeftSideMobileMenu?: () => void
}

const WelcomeComponent = (props: IWelcomeComponentProps) => {
  const leftNavEnabled = stringToBoolean(process.env.REACT_APP_LEFT_NAVIGATION_ENABLED)
  const welcome = useAppSelector((state) => state?.welcome?.welcome)
  const cmsEditPermission = useAppSelector(
    (state) => state?.user?.user?.permissions?.includes('CMS') && state?.webPartEditor?.editContentToggle
  )
  const webPartContent = useAppSelector(marketingContent)
  const isContentEditorEnabled = cmsEditPermission && webPartContent
  const dispatch = useAppDispatch()
  const getWebPartZoneByName = (zoneName: string): IZone | undefined => {
    return webPartContent?.zones?.find((zone) => zone.name === zoneName)
  }
  const getWelcomeZoneContentByName = (zoneName: string): string => {
    let currentZone = welcome?.zones?.find((zone) => zone.name === zoneName)
    if (currentZone) {
      let tempHtml = parse(currentZone.content)
      let contentType = ''
      try {
        contentType =
          (tempHtml.firstChild as HTMLElement).attributes['data-ContentType'] ||
          (tempHtml.firstChild as HTMLElement).attributes['data-contenttype']
        if (!contentType) {
          contentType = CMSEditorTypes.HTMLContent
          const imagesCount = tempHtml.getElementsByTagName('img').length
          if (imagesCount === 1) {
            contentType = CMSEditorTypes.SingleImageLink
          }
          if (imagesCount > 1) {
            contentType = CMSEditorTypes.RotatingImages
          }
        }
      } catch (e) { }
      let template = ''
      switch (contentType) {
        case CMSEditorTypes.SingleImageLink: {
          let tempImgPoco = getImgValues(tempHtml)
          template = singleImageTemplate
          copyImagePropertiesAsNew(tempImgPoco)
          return fillSingleImage(template, tempImgPoco)
        }

        case CMSEditorTypes.RotatingImages: {
          let rotImages = getRotatingImagesValues(tempHtml)
          template = rotatingImagesTemplate
          // Removes leftover arrows from old implementation of the cms
          rotImages = rotImages.filter((image) => !isAbsolutePath(image.imageUrl))
          rotImages.forEach((image, index) => {
            rotImages[index] = copyImagePropertiesAsNew(image)
          })
          return fillRotatingImages(template, rotImages)
        }

        default: {
          return currentZone.content
        }
      }
    }
    return ''
  }
  const isAbsolutePath = (path: string) => {
    return path.startsWith('/');
  }
  const copyImagePropertiesAsNew = (image: IImagePoco) => {
    image.newCurrentLinkType = image.currentLinkType
    image.newCurrentLink = image.currentLink
    image.newLinkOpenNewTab = image.linkOpenNewTab
    return image
  }
  const onPromote = (zoneName: string) => {
    dispatch(
      promote({
        TempContent: getWebPartZoneByName(zoneName)?.tempContent as string,
        ContentType: 1,
        TempFolderPath: getWebPartZoneByName(zoneName)?.tempFolderPath as string,
      })
    )
    dispatch(getWelcome())
  }
  const onDiscard = (zoneName: string) => {
    dispatch(discard({ TempFolderPath: getWebPartZoneByName(zoneName)?.tempFolderPath as string }))
  }
  if (!welcome || welcome.anonymousId === '') return <></>
  return (
      <Container>
        <main id='main' role='main' className='pb-3'>
          <Row>
            {leftNavEnabled && (
              <Col className={`${props.isLeftSideMobileMenuExpand ? '' : 'd-none '} d-xl-block d-lg-block`}>
                <LeftMenuComponent items={welcome.leftCatalog} toggleLeftSideMobileMenu={props.toggleLeftSideMobileMenu} />
              </Col>
            )}
            <Col md={12} lg={leftNavEnabled ? 9 : 12} xl={leftNavEnabled ? 9 : 12}>
              <Row className='welcome'>
                <div className='col-12 col-md-12 col-sm-12 first-image position-relative' id='mainZoneImage'>
                  {getWebPartZoneByName(WelcomeZonesNames.MainZone)?.tempContent
                    ? htmlParser(getWebPartZoneByName(WelcomeZonesNames.MainZone)?.tempContent as string)
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.MainZone))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent
                      zone={getWebPartZoneByName(WelcomeZonesNames.MainZone)}
                      onPromote={onPromote}
                      onDiscard={onDiscard}
                    />
                  )}
                </div>
                <div className='second-image position-relative' id='welcomeZoneImage'>
                  {getWebPartZoneByName(WelcomeZonesNames.WelcomeZone)?.tempContent
                    ? htmlParser(getWebPartZoneByName(WelcomeZonesNames.WelcomeZone)?.tempContent as string)
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.WelcomeZone))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent
                      zone={getWebPartZoneByName(WelcomeZonesNames.WelcomeZone)}
                      onPromote={onPromote}
                      onDiscard={onDiscard}
                    />
                  )}
                </div>
                <div className='position-relative' id='additionalZone1Image'>
                  {getWebPartZoneByName(WelcomeZonesNames.Zone1)?.tempContent
                    ? htmlParser(getWebPartZoneByName(WelcomeZonesNames.Zone1)?.tempContent as string)
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone1))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent zone={getWebPartZoneByName(WelcomeZonesNames.Zone1)} onPromote={onPromote} onDiscard={onDiscard} />
                  )}
                </div>
                <div className='four-image position-relative' id='additionalZone2Image'>
                  {getWebPartZoneByName(WelcomeZonesNames.Zone2)?.tempContent
                    ? htmlParser(getWebPartZoneByName(WelcomeZonesNames.Zone2)?.tempContent as string)
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone2))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent zone={getWebPartZoneByName(WelcomeZonesNames.Zone2)} onPromote={onPromote} onDiscard={onDiscard} />
                  )}
                </div>
                <div className='five-image position-relative' id='additionalZone3Image'>
                  {getWebPartZoneByName(WelcomeZonesNames.Zone3)?.tempContent
                    ? htmlParser(getWebPartZoneByName(WelcomeZonesNames.Zone3)?.tempContent as string)
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone3))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent zone={getWebPartZoneByName(WelcomeZonesNames.Zone3)} onPromote={onPromote} onDiscard={onDiscard} />
                  )}
                </div>
                <div className='five-image position-relative' id='additionalZone4Image'>
                  {getWebPartZoneByName(WelcomeZonesNames.Zone4)?.tempContent
                    ? htmlParser(getWebPartZoneByName(WelcomeZonesNames.Zone4)?.tempContent as string)
                    : htmlParser(getWelcomeZoneContentByName(WelcomeZonesNames.Zone4))}
                  {isContentEditorEnabled && (
                    <CmsButtonsComponent zone={getWebPartZoneByName(WelcomeZonesNames.Zone4)} onPromote={onPromote} onDiscard={onDiscard} />
                  )}
                </div>
              </Row>
            </Col>
          </Row>
        </main>
      </Container>
  )
}
export default WelcomeComponent
